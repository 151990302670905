<script lang="tsx">
import { defineComponentBaseUiSelect } from '@core/app/components/base/ui/BaseUiSelect.vue'
import { IconChevron } from '#components'
import type { BaseUiInputColors } from './BaseUiInput.vue'

type SelectColors = BaseUiInputColors
type SelectVariants = 'solid' | 'outlined'
type SelectSizes = 'medium'

export default defineComponentBaseUiSelect<SelectColors, SelectVariants, SelectSizes>({
    props: {
        color: {
            default: 'black',
        },
        variant: {
            default: 'outlined',
        },
        size: {
            default: 'medium',
        },
    },
    slots: {
        dropdownIcon: () => <IconChevron size="xs" />,
    },
})

</script>

<style lang="scss" scoped>
@use "@core-scss/components/BaseUiSelect" as *;

@include select--disabled {
    opacity: 0.5;
}

@include select--error {
    background-color: $mon-c-white;
    outline: 1px solid $mon-c-danger-500;

    &:focus-within {
        outline-width: 2px;
        outline-offset: 0;
    }
}

@include placeholder {
    color: $mon-c-black;

    @include mon-text-small;
    @include mon-font-light;
}

@include side-content {
    color: $mon-c-black;
}

// --------------------------------------------------------------------
// Select sizes

@include select--size('medium') {
    @include set-item-gap(0.625rem);
    @include set-padding(0.85rem, 1.5rem);
    border-radius: $mon-border-radius;
}

// --------------------------------------------------------------------
// Select colors

@include select--color('black') {
    @include select--variant('solid', '&') {
        background-color: $mon-c-black;
        color: $mon-c-white;
    }

    @include select--variant('outlined', '&') {
        background-color: $mon-c-white;
        color: $mon-c-black;
        border: 1px solid $mon-c-black;
    }
}

</style>
